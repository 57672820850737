import { useLocation } from '@kaliber/routing'

export function Redirect({ to }) {
  const location = useLocation()
  const returnUrl = `?${new URLSearchParams({ returnPath: location.pathname }).toString()}`
  const target = `${to}${returnUrl}`

  React.useEffect(() => { window.location.href = target }, [target] )

  return <span role='presentation' />
}

